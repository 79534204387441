@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    src: url(styles/fonts/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(styles/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}

.icon {
    margin-right: 10px;
}

.auto-resize {
    width: 100%;
    height: 100%;
}

.mw-1400 {
    max-width: 1400px;
}

.gray {
    color: gray;
}

.v-ql {
    min-height: 200px !important;
    min-width: 200px !important;
    font-size: 80px !important;
    background-color: black !important;
    color: white !important;
}

::-webkit-scrollbar {
    display: none;
}
